/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
COMMAND AUTOPREFIXER FOR POSTCSS: postcss resources/public/css/lambdawerk.css -u autoprefixer -o resources/public/css/lambdawerk_prefixed.css
*/

@media screen {
  html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
    box-sizing: border-box;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after, q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  /* CUSTOM CSS */
  html, body {
    height: 100vh;
    font-family: 'Roboto', sans-serif;
    font-weight: 300;
    color: #4A4A4A;
    margin: 0;
    font-size: 100%;
    line-height: 1.28em;
  }
  html {
    background-color: #E5E5E5;
    height: 100vh;
    padding-bottom: 20px;
  }
  body {}
  /* TYPOGRAPHY */
  a {
    font-weight: 400;
    line-height: 1.1em;
    color: #43869A;
    text-decoration: none;
    cursor: pointer;
  }
  a:hover {
    font-weight: 500;
  }
  .current {
    color: #4A4A4A !important;
    font-weight: 500;
  }
  p {
    margin-top: 3px;
    margin-bottom: 6px;
  }
  .no-wrap {
    white-space: nowrap;
  }
  .job {
    font-size: 1.2em;
    display: block;
    padding-top: 20px;
    color: #00A5C4;
  }
  ul.diamond {
    padding: 0;
    list-style-image: url("/images/diamond_primary.jpg");
    padding: 0;
    margin: 0;
  }
  ul.diamond>li {
    margin-bottom: 7px;
  }
  h1 {
    /*border-left: 1px solid #00A5C4;
	padding-left: 20px;*/
    font-size: 2.236em;
    line-height: 1.1em;
    font-style: italic;
    font-weight: 100;
    color: #00A5C4;
    padding-top: 50px;
    padding-bottom: 50px;
  }
  h2 {
    font-weight: 400;
    font-size: 1.36em;
    padding: 0;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  h3 {
    font-size: 1.2em;
    font-weight: 400;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 6px;
  }
  h4 {
    margin-top: 0px;
    margin-bottom: 0px;
    font-weight: 400;
  }
  .initial {
    font-weight: 400;
  }
  .caption {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .caption p, .caption h3 {
    margin-bottom: -5px;
  }
  .caption h3 {
    margin-top: 10px;
  }
  small {
    margin-top: 20px;
    font-weight: 300;
    font-size: 0.9em;
    line-height: 1.8em;
    opacity: 0.7;
  }
  /* LAYOUT */
  .shoulders {
    padding-left: 260px;
    padding-right: 260px;
  }
  .header {
    height: 100px;
    padding-top: 0px;
    padding-bottom: 0px;
    background-color: #F5F5F5;
    z-index: 10;
  }
  .corner {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 360px;
    height: auto;
    z-index: -10;
  }
  .brand {
    padding-left: 20px;
    margin-top: 30px;
    display: block;
    float: left;
    height: auto;
    object-fit: contain;
  }
  .logo {
    max-width: 200px;
  }
  nav {
    display: block;
    float: right;
    padding-right: 20px;
    margin-top: 51px;
    margin-bottom: 0px;
    min-width: 340px;
  }
  .tab {
    display: inline-block;
    margin-left: 30px;
    font-size: 1em;
  }
  /* CONTENT */
  .content-wrapper {
    top: 120px;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .content {
    padding: 20px;
    display: flex;
    background-color: white;
  }
  .row {
    flex-direction: row;
    justify-content: space-between;
  }
  .column {
    flex-direction: column;
  }
  .text-left {
    padding-right: 20px;
    width: 50%;
  }
  .float {
    width: 50%;
  }
  .only-left {
    width: 75%;
  }
  .quotation {
    height: auto;
  }
  .whitespace15 {
    height: 15px;
  }
  .whitespace20 {
    height: 20px;
  }
  .whitespace30 {
    height: 30px;
  }
  .whitespace40 {
    height: 40px;
  }
  .whitespace60 {
    height: 60px;
  }
  .hairline-low {
    border-bottom: solid 0.5px #E5E5E5;
  }
  .middle {
    padding-top: 20px;
    padding-bottom: 40px;
  }
  .below {
    align-self: flex-end;
    margin-top: auto;
  }
  .low {
    border-top: solid 1px #E5E5E5;
    margin-top: auto;
    padding-top: 20px;
    margin-top: 20px;
  }
  /* TEAM */
  .team {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }
  .employee {
    width: 25%;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    flex-direction: column;
  }
  .img-responsive {
    width: 70%;
    min-width: 60%;
    height: auto;
    opacity: 0.9;
  }
  .img-responsive-big {
    width: 100%;
    height: auto;
  }
  .desaturate {
    filter: saturate(80%);
  }
  /* IMPRINT */
  table {
    margin-bottom: 20px;
  }
  tr>td:first-child {
    width: 140px;
  }
  td {
    vertical-align: top;
  }
  .angaben {
    font-size: 1.2em;
    font-weight: 300;
  }
  .indent {
    padding-left: 240px;
    margin-top: -21px;
  }
  /* FOOTER */
  footer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-top: 0px;
    padding-bottom: 30px;
  }
  hr {
    border: 0;
    height: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  }
  .copyright {
    margin-left: 20px;
  }
  .footer-links>a {
    margin-right: 20px;
  }
  /* Responsive Styles */
  @media (max-width: 1500px) {
    .shoulders {
      padding-left: 120px;
      padding-right: 120px;
    }
    .corner {
      width: 220px;
    }
  }
  @media(max-width:1200px) {
    .shoulders {
      padding-left: 40px;
      padding-right: 40px;
    }
    .corner {
      width: 184px;
    }
    .brand {
      height: 30%;
    }
    .logo {
      width: 80%;
      margin-bottom: 0px;
    }
    .content-wrapper {
      min-width: 360px;
    }
    .content {
      padding: 10px;
    }
    .quotation {
      padding-top: 20px;
      padding-bottom: 20px;
    }
    .employee {
      width: 33%;
      padding-left: 10px;
      padding-right: 10px;
    }
    .only-left {
      width: 100%;
    }
    .indent {
      padding-left: 40px;
      margin-top: 20px;
    }
    h1 {
      margin-top: 20px;
    }
    footer {
      margin-bottom: 0px;
      padding-top: 20px;
      padding-bottom: 30px;
    }
  }
}

@media (max-width: 640px) {
  .header {
    height: 114px;
  }
  nav {
    margin-top: 18px;
  }
}

@media (max-width: 590px) {
  .shoulders {
    padding-left: 16px;
    padding-right: 16px;
  }
  .header {
    height: 115px;
  }
  nav {
    margin-top: 18px;
  }
  .content-wrapper {
    padding-top: 0px;
  }
  .row {
    display: flex;
    flex-direction: column;
  }
  .text-left {
    width: 100%;
  }
  .float {
    width: 100%;
    margin-top: 20px;
  }
  .employee {
    width: 50%;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media (max-width: 500px) {
  .shoulders {
    padding-left: 5px;
    padding-right: 5px;
  }
  .logo {
    width: 60%;
    margin-bottom: 0px;
  }
  nav {
    margin-right: -68px;
  }
  .tab {
    margin-left: 18px;
  }
  .content-wrapper {
    min-width: 300px;
  }
  .employee {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  footer {
    flex-direction: column;
    justify-content: flex-end;
  }
  .copyright {
    margin-left: 10px;
    width: 280px;
  }
  .footer-links {
    margin-top: 10px;
    margin-left: 10px;
  }
}

@media (max-width: 300px) {
  nav {}
  .corner {
    width: 157px;
  }
  footer {
    margin-right: 0px;
  }
}


/*end of @media screen*/

@media print {
  body {
    font: 13pt 'Roboto', Geneva, sans-serif;
    font-weight: 300;
    line-height: 1.5;
  }
  nav, footer, .header-bg, .header-bg-triangle, .img-responsive-big, .corner {
    display: none;
  }
  section {
    background: none
  }
  .img-responsive {
    width: 300px;
    height: auto;
    opacity: 0.9;
  }
  tr>td:first-child {
    width: 180px;
  }
  .job {
    font-size: 1.68em;
    display: block;
    padding-top: 22px;
    color: #00A5C4;
  }
}
